// Base Class Definition
// -------------------------

:root {
    --#{$prefix}icon-font-family: #{inspect($fa-font-family)};
}

.#{$fa-css-prefix} {
    display: inline-block;
    font: normal normal normal #{$fa-font-size-base}/#{$fa-line-height-base} var(--#{$prefix}icon-font-family); // shortening font declaration
    font-size: inherit; // can't have font-size inherit on line above, so need to override
    text-rendering: auto; // optimizelegibility throws things off #1094
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}