/* FONT PATH
 * -------------------------- */

@font-face {
    font-family: '#{$fa-font-family}';
    src:
        url('#{$fa-font-path}/#{$fa-font-filename}.woff2?v=#{$fa-version}') format('woff2'),
        url('#{$fa-font-path}/#{$fa-font-filename}.woff?v=#{$fa-version}') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: auto;
}
